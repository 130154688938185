import React, { useEffect,useState } from 'react'
import { BrowserRouter, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import db from '../db';

export default function Header () {
let location = useLocation();

let menu;

const toggleMenu = (e) =>{
  menu = document.querySelector("ul.menu");
  if(menu.classList.contains("open")){
    menu.classList.remove("open")
  }else{
    menu.classList.add("open");
  }
}


  useEffect(()=>{
    window.onresize = (e)=>{
      menu = document.querySelector("ul.menu");
      if(window.innerWidth>767.20){
        menu.classList.remove("open")
      }
    }
  },[])




  return (
    <header className={`header${['/','/ana-səhifə','/ana-səhifə/'].includes(location.pathname)?' dark':''}`}>
      <div className='head-line'>
        <ul className="top-links">
        <li>
            <a href={db.contacts.map} target='_blank'><img src="/Images/Icons/Contact/map1.png"/></a>
          </li>
          <li>
            <a href={db.contacts.instagram} target='_blank'><img src="/Images/Icons/Contact/ig1.png"/></a>
          </li>
          <li>
            <a href={db.contacts.facebook} target='_blank'><img src="/Images/Icons/Contact/fb1.png"/></a>
          </li>
          {/* <li>
            <a href={db.contacts.linkedin} target='_blank'><img src="/Images/Icons/Contact/li.png"/></a>
          </li>    */}
          <li>
            <a href={db.contacts.whatsappLink} target='_blank'><img src="/Images/Icons/Contact/wa1.png"/></a>
          </li>
        </ul>
      </div>
      <div className="menu-line">
      <div className='logo'>
        <Link to="/"><img src='/Images/Logos/logo_negative.png' /></Link>
      </div>
      <ul className='menu'>
      <li>
          <Link to='/'>Ana səhifə</Link>
        </li>
        <li>
          <Link to='/təlimlər'>Təlimlər</Link>
        </li>
        {/* <li>
          <Link to='/xidmətlər'>Xidmətlər</Link>
        </li> */}
        <li>
        <Link to='/əlaqə'>Əlaqə</Link>
        </li>
      </ul>
      <div className="mobile-menu" onClick={toggleMenu}>
        <img src="/Images/Icons/mobile-menu.svg"/>
      </div>
      </div>

    </header>
  )
}
