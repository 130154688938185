import logo from './logo.svg'
import React from 'react'
import './App.scss'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import About from './Pages/About.js'
import Home from './Pages/Home.js'
import Contacts from './Pages/Contacts.js'
import Courses from './Pages/Courses.js'
import Course from './Pages/Course.js'
import Error404 from './Pages/Error404.js'
import bootstrap from 'bootstrap'
import Services from './Pages/Services.js'

function App () {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='ana-səhifə' element={<Home />} />
            <Route path='haqqımızda' element={<About />} />
            <Route path='əlaqə' element={<Contacts />} />
            <Route path='xidmətlər' element={<Services />} />
            <Route path='təlimlər' element={<Courses />} />
            <Route path='təlim/:title' element={<Course />} />
            <Route path='/*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
