import React, { useEffect } from 'react'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import axios from 'axios'
import Variables from './../global.js'

export default function Layout({children,pageName=null}){

    return (
        <React.Fragment>
            <Header/>
                {children}
            <Footer/>
        </React.Fragment>
    )
}