import React, { useEffect } from 'react'
import Layout from '../Layouts/Layout'
import db from '../db.js'
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import Call from '../Components/Call.js'
import WeAreHere from '../Components/WeAreHere.js'
import DocumentMeta from 'react-document-meta'

export default function Home () {
  const meta = {
    title: 'Əsas səhifə | Kodart Tətbiq və Təlim Mərkəzi',
    description:'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti təklif edir və burada təcrübəli müəllimlər tərəfindən Front-end proqramlaşdırma, Back-end proqramlaşdırma və Məktəblilər üçün proqramlaşdırmanın əsasları dərsləri tədris olunur.',
    canonical: 'https://kodart.az',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
      }
    }
  }

  const location = useLocation()
  const navigate = useNavigate()
  document.title = ''
  useEffect(() => {
    if (location.hash && document.querySelector(location.hash)) {
      let element = document.querySelector(location.hash)
      console.dir(element.offsetTop)
      window.scrollTo({
        top: element.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [location])

  return (
    <DocumentMeta {...meta}>
      <Layout>
        <main data-page='home'>
          <section className='slider'>
            {/* <img className="main" src='/Images/Background/notebook.jpg' /> */}
            <img className='background' src='/Images/Background/code.jpg' />
            <div className='center-content'>
              <div className='main-slogan'>Yarat,<br/>yenilən!</div>
              <div className='secondary-slogan'>Sabaha addımla!</div>
              <div className='engagement-button'>
                <a href={db.contacts.whatsappLink}><img src="/Images/Icons/Contact/wa1.png"/><span className="button-text">Bizə yazın</span></a>
              </div>
            </div>
          </section>
          <section className='section-container training-section'>
            <div className='section-header'>
              <h2>Təlimlərimiz</h2>
              <div className='green-dot-line'></div>
            </div>
            <div className='row'>
              {db.courses.sort((a,b) => +a.id - +b.id).map(c => {
                return (
                  <div key={c.id} className='col-12 col-lg-4'>
                    <div className='course-card'>
                      <h3>{c.name}</h3>
                      <img className='card-icon' src={c.image} />
                      <p className='course-description'>{c.description}</p>
                      <Link
                        to={`/təlim/${c.title}`}
                        className='more-info-button'
                      >
                        Ətraflı məlumat
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
          {/* <Call /> */}
          <WeAreHere />
        </main>
      </Layout>
    </DocumentMeta>
  )
}
