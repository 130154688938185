const db = {
    name:"Kodart Tətbiq və Təlim mərkəzi",
    logo:"1",
    courses:[
        {
            id:1,
            title:"məktəblilər-üçün-proqramlaşdırmanın-əsasları",
            name:"Məktəblilər üçün Proqramlaşdırmanın əsasları",
            image:'/Images/Courses/basics.png',
            subheader:"test",
            description:"11-16 yaş arası məktəb şagirdləri üçün nəzərdə tutulmuş tədris proqramında proqramlaşdırmanın təməlləri aşılanır.",
            subjects:["İlkin kompüter bilikləri","Alqoritmlər","Scratch","HTML","CSS", "Javascript"],
            duration:"4 ay",
            program:[
                {
                    order:"1",subject:"İlkin kompüter bilikləri", description:"",duration:""
                },
                {
                    order:"2",subject:"Scratch", description:"",duration:""
                },
                {
                    order:"3",subject:"Alqoritmlər", description:"",duration:""
                },
                {
                    order:"4",subject:"HTML", description:"",duration:""
                },
                {
                    order:"5",subject:"CSS", description:"",duration:""
                },
                {
                    order:"6",subject:"Javascript", description:"",duration:""
                }
            ]
        },
        {
            id:2,
            name:"Front-end proqramlaşdırma \n (onlayn)",
            title:"front-end-proqramlaşdırma",
            image:'/Images/Courses/front-end.png',
            subheader:"test",
            description:"İstifadəçi interfeyslərinin hazırlanması üzrə təşkil edilən təlim, front-end proqramçı kimi fəaliyyət göstərmək istəyən bütün yaş təbəqələri üçün nəzərdə tutulur.",
            subjects:["HTML","CSS","Bootstrap","Javascript","React.js","Redux","git"],
            duration:"6 ay",
            program:[
                {
                    order:"1",subject:"Veb proqramlaşdırmaya giriş", description:"",duration:""
                },
                {
                    order:"2",subject:"HTML", description:"",duration:""
                },
                {
                    order:"3",subject:"CSS və Preprosessorları", description:"",duration:""
                },
                {
                    order:"4",subject:"Responsiv dizayn", description:"",duration:""
                },
                {
                    order:"5",subject:"Javascript", description:"",duration:""
                },
                {
                    order:"6",subject:"React.js", description:"",duration:""
                },
                {
                    order:"7",subject:"Redux", description:"",duration:""
                },
                {
                    order:"7",subject:"Deployment", description:"",duration:""
                }
            ]
        },
        {
            id:3,
            title:"back-end-proqramlaşdırma",
            name:"Back-end proqramlaşdırma \n (onlayn)",
            image:'/Images/Courses/back-end.png',
            subheader:"test",
            description:"Əmək bazarında Back-end proqramçı ixtisası üzrə tələb olunan biliklər və buna uyğun praktiki məşğələlər",
            subjects:["C#",".NET Core","API","MSSQL","EF Core","git",],
            duration:"6 ay",
            program:[
                {
                    order:"1",subject:"C#", description:"",duration:""
                },
                {
                    order:"2",subject:"MS SQL", description:"",duration:""
                },
                {
                    order:"3",subject:"Entity Framework Core", description:"",duration:""
                },
                {
                    order:"4",subject:"RESTful API", description:"",duration:""
                },
                {
                    order:"5",subject:"Deployment", description:"",duration:""
                }
            ]
        }
    ],
    solutions:[
        {title:"Veb saytlar",subtitle:"",description:"",order:"1",image:"./Images/Services/web.png",enabled:true},
        {title:"Xüsusi biznes həlləri",subtitle:"",description:"",order:"2",image:"./Images/Services/businesssolutions.webp",enabled:true},
        {title:"Konsaltinq",subtitle:"",description:"",order:"3",image:"./Images/Services/consulting.png",enabled:true}
    ],
    contacts:{
        phone:"50 992 00 10",
        phone_intl:"+994 50 992 0010",
        whatsapp:"+994509920010",
        eMail:"info@kodart.az",
        linkedin:"https://linkedin.com/company/kodartaz",
        instagram:"https://instagram.com/kodart.az",
        facebook:"https://facebook.com/kodart.az",
        whatsappLink:"https://wa.link/g3nct3",
        address:"Xırdalan şəhəri, Qalubiyyə küçəsi, 31. Heydər Əliyev Mərkəzinin yanı",
        map:"https://maps.app.goo.gl/bu2bvixwgfTmypkT7",
        coordinates:{
            latitude:"42",
            longtitude:"45"
        }
    }
}

export default db;