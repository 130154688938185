import React from 'react'

export default function Error404 () {
  return (
    <React.Fragment>
      <p>Not Found</p>
      <p>404</p>
    </React.Fragment>
  )
}
