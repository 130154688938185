import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import db from '../db'
import Layout from '../Layouts/Layout'
import DocumentMeta from 'react-document-meta'

export default function Course () {
  const title = useParams().title
  const course = db.courses.find(c => c.title == title)
  const meta = {
    title: `${course.name} | Kodart Tətbiq və Təlim Mərkəzi`,
    description:
      'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti göstərir və mərkəzimizdə təcrübəli müəllimlər tərəfindən Front-end kodlaşdırma, Back-end kodlaşdırma və Məktəblilər üçün kodlaşdırmanın əsasları dərsləri tədris olunur.',
    canonical: `https://kodart.az/təlimlər`,
    meta: {
      charset: 'utf-8',
      name: {
        keywords:
          'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
      }
    }
  }

  window.scroll(0, 0)

  useEffect(() => {}, [])

  return (
    <DocumentMeta {...meta}>
      <Layout>
        <main data-page='course' className='page-container'>
          <section className='section-container general-informations'>
            <h1 className='course-header'>{course.name}</h1>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-6'>
                <div className='course-subjects'>
                  {course.subjects.map((s, i) => {
                    return (
                      <span key={i} className='subject-tag'>
                        {s}
                      </span>
                    )
                  })}
                </div>
                <p className='course-description'>{course.description}</p>
                <p className='course-duration'>
                  <b>Kursun müddəti:</b> {course.duration}
                </p>
                <div className='course-program'>
                  <b>Proqram:</b>
                  <ul>
                    {course.program
                      .sort((a, b) => +a.order - +b.order)
                      .map((s, i) => {
                        return (
                          <li key={i}>
                            <h5>
                              <i className='check-icon'>L</i>
                              {s.subject}
                            </h5>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
              <div className='col-0 col-sm-0 col-md-6'>
                <div className='course-image'>
                  <img src={course.image} />
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </DocumentMeta>
  )
}
