let Variables = {
    base_url:"",
};

if(process.env.NODE_ENV==='development'){
    Variables.base_url=process.env.REACT_APP_DEVELOPMENT_URL
    
}else if(process.env.NODE_ENV==='production'){
    Variables.base_url=process.env.REACT_APP_PRODUCTION_URL
}
console.log(process.env.NODE_ENV);

export default Variables