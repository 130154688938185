import React from 'react'
import Layout from '../Layouts/Layout'
import db from '../db'
import Call from '../Components/Call'
import WeAreHere from '../Components/WeAreHere'
import DocumentMeta from 'react-document-meta'

export default function Contacts () {
  const meta = {
    title: 'Əlaqə məlumatları | Kodart Tətbiq və Təlim Mərkəzi',
    description: 'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti göstərir və mərkəzimizdə təcrübəli müəllimlər tərəfindən Front-end kodlaşdırma, Back-end kodlaşdırma və Məktəblilər üçün kodlaşdırmanın əsasları dərsləri tədris olunur.',
    canonical: 'https://kodart.az/əlaqə',    
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
      }
    }
  };
  const contactInfo = db.contacts
  return (
    <DocumentMeta {...meta}>
    <Layout>
      <main data-page='contacts'>
        <WeAreHere/>
        <section className='section-container address'>
          <div className='section-header'>
            <h1>Əlaqə məlumatları</h1>
            <div className='green-dot-line'></div>
          </div>
          <div className='contacts-details'>
            <div className='contacts-item'>
              <table>
                <tbody>
                  <tr className='contacts-item'>
                    <td className="contact-icon">
                      <img src='./Images/Icons/phone_white.svg' />
                    </td>
                    <td className="contact-value">
                      <span><a href={`tel:${db.contacts.phone}`}>+994 {db.contacts.phone}</a></span>
                    </td>
                  </tr>
                  <tr className='contacts-item'>
                    <td className="contact-icon">
                      <img src='./Images/Icons/whatsapp_white.svg' />
                    </td>
                    <td className="contact-value">
                      <span><a href={`https://wa.me/${db.contacts.whatsapp}`} target='_blank'>{db.contacts.phone_intl}</a></span>
                    </td>
                  </tr>
                  <tr className='contacts-item'>
                    <td className="contact-icon">
                      <img src='./Images/Icons/mail_white.svg' />
                    </td>
                    <td className="contact-value">
                      <span><a href={`mailTo:${db.contacts.eMail}`}>{db.contacts.eMail}</a></span>
                    </td>
                  </tr>
                  <tr className='contacts-item'>
                    <td className="contact-icon">
                      <img src='./Images/Icons/map_white.svg' />
                    </td>
                    <td className="contact-value">
                      <span><a href={`${db.contacts.location}`} target='_blank'>{db.contacts.address}</a></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* <Call light={true}/> */}
      </main>
    </Layout>
    </DocumentMeta>
  )
}
