import React from 'react'
import { useParams } from 'react-router'
import db from '../db'
import Layout from '../Layouts/Layout'
import { Link } from 'react-router-dom'
import WeAreHere from '../Components/WeAreHere'
import DocumentMeta from 'react-document-meta'

export default function Services () {
  const meta = {
    title: 'Xidmətlər | Kodart Tətbiq və Təlim Mərkəzi',
    description: 'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti göstərir və mərkəzimizdə təcrübəli müəllimlər tərəfindən Front-end kodlaşdırma, Back-end kodlaşdırma və Məktəblilər üçün kodlaşdırmanın əsasları dərsləri tədris olunur.',
    canonical: 'https://kodart.az/xidmətlər',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
    } 
    }
  };
  const title = useParams().title
  return (
    <DocumentMeta {...meta}>
    <Layout>
      <main data-page='services'>
        <section className='section-container services-section'>
          <div className='section-header'>
            <h1>Xidmətlərimiz</h1>
            <div className='green-dot-line'></div>
          </div>
          <div className='row'>
            {db.solutions.map(c => {
              return (
                <div key={c.id || 0} className='col-12 col-lg-4'>
                  <div className='service-card'>
                    <h2>{c.title}</h2>
                    <img className='service-icon' src={c.image || ''} />
                    <p className='service-description'>{c.description}</p>
                    <Link to={`tel:+994509920010`} className='lets-talk-button'>
                      <img src='./Images/Icons/phone.svg' />
                      <span>Gəlin, danışaq</span>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
        <WeAreHere/>
      </main>
    </Layout>
    </DocumentMeta>
  )
}
