import React, { useEffect,useState } from 'react'
import { BrowserRouter, Routes, Route, Link, useParams, useLocation } from 'react-router-dom'

export default function WeAreHere ({light}) {

  return (
    <section className='section-container we-are-here'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='map'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2146.798157418264!2d49.74777365744548!3d40.452449293816684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4030859824b1e779%3A0xcf4e535493ee774b!2sKodart!5e0!3m2!1str!2saz!4v1701642401146!5m2!1str!2saz" width="250" height="250" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className='col-12 col-md-6 text-sm-center text-md-start'>
              <div className='banner-label'>
                <p>Biz buradayıq;</p>
                <p>İnkişafın yanında!</p>
              </div>
            </div>
          </div>
        </section>
  )
}
