import React from 'react'
import { useLocation } from 'react-router'
import {Link} from 'react-router-dom'
import db from '../db';

export default function Footer () {
    const path = useLocation().pathname;
    console.log('path',path);
    let light = path==='/' || decodeURI(path)==='/əlaqə'
    let style = light?'':' dark';
  return (
    <footer className={`footer${style}`}>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <div className='logo'>
            <img src={`/Images/Logos/logo${light?'':'_negative'}.png`} />
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <ul className='courses-menu'>
            <li className='list-header'><Link to="/təlimlər">Təlimlərimiz</Link></li>
            {db.courses.map(c=>{
                return (
                    <li key={c.title}><Link to={`/təlim/${c.title}`}>{c.name}</Link></li>
                )
            })}
          </ul>
        </div>
        <div className='col-12 col-md-4'>
          <div className='contact-us'>
            <ul className='services-menu'>
              <li className='list-header'>Əlaqə</li>
              <li><a href={`tel:${db.contacts.phone}`}>+994{db.contacts.phone}</a></li>
              <li><a href={`mailto:${db.contacts.eMail}`}>{db.contacts.eMail}</a></li>
              <li><a href={`${db.contacts.location}`} target='_blank'>{db.contacts.address}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
