import React from 'react'
import Layout from '../Layouts/Layout'
import db from '../db'
import { Link } from 'react-router-dom'
import Call from '../Components/Call'
import DocumentMeta from 'react-document-meta'

export default function Courses(){
  const meta = {
    title: 'Təlimlər | Kodart Tətbiq və Təlim Mərkəzi',
    description: 'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti göstərir və mərkəzimizdə təcrübəli müəllimlər tərəfindən Front-end kodlaşdırma, Back-end kodlaşdırma və Məktəblilər üçün kodlaşdırmanın əsasları dərsləri tədris olunur.',
    canonical: 'https://kodart.az/təlimlər',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
      }
    }
  };
  window.scroll(0,0);
    return (
      <DocumentMeta {...meta}>
        <Layout>
        <main data-page='courses'>
        <section className='section-container training-section'>
          <div className='section-header'>
            <h1>Təlimlərimiz</h1>
            <div className='green-dot-line'></div>
          </div>
          <div className='row'>
            {db.courses.sort((a,b) => +a.id - +b.id).map(c => {
              return (
                <div key={c.id} className='col-12 col-lg-4'>
                  <div className='course-card'>
                    <h2>{c.name}</h2>
                    <img className='card-icon' src={c.image} />
                    <p className='course-description'>{c.description}</p>
                    <Link to={`/təlim/${c.title}`} className='more-info-button'>
                      Ətraflı məlumat
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
        {/* <Call/> */}
        </main>
      </Layout>
      </DocumentMeta>
    )
}