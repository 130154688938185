import React from 'react'
import Layout from '../Layouts/Layout'
import DocumentMeta from 'react-document-meta';

export default function About(){
    const meta = {
        title: 'Haqqımızda | Kodart Tətbiq və Təlim Mərkəzi',
        description: 'Kodart Tətbiq və Təlim Mərkəzi Xırdalanda fəaliyyət göstərir. Mərkəzimiz bir neçə istiqamət üzrə proqram təminatı xidməti göstərir və mərkəzimizdə təcrübəli müəllimlər tərəfindən Front-end kodlaşdırma, Back-end kodlaşdırma və Məktəblilər üçün kodlaşdırmanın əsasları dərsləri tədris olunur.',
        canonical: 'https://kodart.az/haqqımızda',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'abşeronda,abşeron,abseronda,abseron,masazirda,masazırda,masazir,masazır,xırdalanda,xirdalanda,xırdalan,xirdalan,blok,front-end,back-end,back,front,end,html,css,c#,dotnet,sql,mssql,javascript,scratch,proqramlaşdırma,proqramlasdirma,kodlaşdırma,kodlasdirma,coding,kompüter,computer,it,informasiya,texnologiyalari,texnologiya,abşeron,absheron,xirdalan,xırdalan,masazir,masazır,sulutəpə,sulutepe,hökməli,hokmeli,kodart,kurs,kursu,kurslari,kursları,təlim,təlimi,telim,telimi'
          }
        }
      };

    return (
        <DocumentMeta {...meta}>
        <Layout>
        <p>About</p>
        </Layout>
        </DocumentMeta>

    )
}